import React, { useState, useEffect, useContext } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import AuthLogin from '../profile/auth-login';
import '../assets/styles/PaymentMethods.css';
import AddCard from './AddCard';

const stripePromise = loadStripe('pk_test_51QikgM2LqoSDKNiWnWTbdNFZskz0n89Xsa9sf4fHwrwXPH3sUuzo6w9WulNZ469wP6M8O76iwyvDKDpJPNnWiPlY00tWwdxtuY');

// Custom Modal Component
const Modal = ({ isOpen, onClose, title, children, actions }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-header">
          <h3>{title}</h3>
          <button className="modal-close" onClick={onClose}>&times;</button>
        </div>
        <div className="modal-body">
          {children}
        </div>
        <div className="modal-footer">
          {actions}
        </div>
      </div>
    </div>
  );
};

const PaymentMethods = () => {
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showAddCard, setShowAddCard] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [defaultConfirmOpen, setDefaultConfirmOpen] = useState(false);
  const [selectedMethod, setSelectedMethod] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const ctx = useContext(AuthLogin);

  const fetchPaymentMethods = async () => {
    try {
      setIsLoading(true);
      setError(null);
      
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/payment-methods/${ctx.userId}/${ctx.encryptedPassword}`
      );

      if (!response.ok) {
        throw new Error('Failed to fetch payment methods');
      }

      const data = await response.json();
      setPaymentMethods(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPaymentMethods();
  }, []);

  const handleSetDefault = async (method) => {
    if (method.isDefault) return;
    
    setSelectedMethod(method);
    setDefaultConfirmOpen(true);
  };

  const confirmSetDefault = async () => {
    try {
      setError(null);
      const response = await fetch(`${process.env.REACT_APP_API_URL}/set-default-payment`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: ctx.userId,
          paymentMethodId: selectedMethod.id
        })
      });

      if (!response.ok) {
        throw new Error('Failed to set default payment method');
      }

      await fetchPaymentMethods();
      setSuccessMessage('Default payment method updated successfully');
      setTimeout(() => setSuccessMessage(''), 3000);
    } catch (err) {
      setError(err.message);
    } finally {
      setDefaultConfirmOpen(false);
    }
  };

  const handleDelete = (method) => {
    if (method.isDefault) {
      setError("Cannot delete default payment method while subscription is active");
      return;
    }
    
    setSelectedMethod(method);
    setDeleteConfirmOpen(true);
  };

  const confirmDelete = async () => {
    try {
      setError(null);
      const response = await fetch(`${process.env.REACT_APP_API_URL}/remove-payment-method`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: ctx.userId,
          paymentMethodId: selectedMethod.id
        })
      });

      if (!response.ok) {
        throw new Error('Failed to remove payment method');
      }

      await fetchPaymentMethods();
      setSuccessMessage('Payment method removed successfully');
      setTimeout(() => setSuccessMessage(''), 3000);
    } catch (err) {
      setError(err.message);
    } finally {
      setDeleteConfirmOpen(false);
    }
  };

  const formatExpiryDate = (month, year) => {
    return `${month.toString().padStart(2, '0')}/${year.toString().slice(-2)}`;
  };

  if (isLoading) {
    return <div className="payment-loading">Loading payment methods...</div>;
  }

  return (
    <div className="payment-methods-container">
      {error && (
        <div className="error-message">
          {error}
        </div>
      )}
      
      {successMessage && (
        <div className="success-message">
          {successMessage}
        </div>
      )}

      <div className="payment-header">
        <h3>Payment Methods</h3>
        <button 
          className="payment-add-button"
          onClick={() => setShowAddCard(true)}
        >
          Add New Card
        </button>
      </div>

      <div className="payment-list">
        {paymentMethods.map((method) => (
          <div key={method.id} className="payment-card">
            <div className="payment-card-content">
              <div>
                <div className="payment-card-header">
                  <span className="card-brand">{method.brand}</span>
                  <span className="card-number">•••• {method.last4}</span>
                  {method.isDefault && (
                    <span className="default-badge">Default</span>
                  )}
                </div>
                <div className="expiry-date">
                  Expires {formatExpiryDate(method.expMonth, method.expYear)}
                </div>
                <div className="billing-details">
                  <div>{method.billingDetails.firstName} {method.billingDetails.lastName}</div>
                  <div>
                    {method.billingDetails.street}
                    {method.billingDetails.unit && `, Unit ${method.billingDetails.unit}`}
                  </div>
                  <div>
                    {method.billingDetails.city}, {method.billingDetails.state} {method.billingDetails.zipCode}
                  </div>
                </div>
              </div>
              
              <div className="payment-actions">
                {!method.isDefault && (
                  <button 
                    className="set-default-button"
                    onClick={() => handleSetDefault(method)}
                  >
                    Set as Default
                  </button>
                )}
                <button 
                  className="remove-button"
                  onClick={() => handleDelete(method)}
                  disabled={method.isDefault}
                >
                  Remove
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Delete Confirmation Modal */}
      <Modal
        isOpen={deleteConfirmOpen}
        onClose={() => setDeleteConfirmOpen(false)}
        title="Remove Payment Method"
        actions={
          <>
            <button 
              className="modal-button secondary" 
              onClick={() => setDeleteConfirmOpen(false)}
            >
              Cancel
            </button>
            <button 
              className="modal-button primary" 
              onClick={confirmDelete}
            >
              Remove
            </button>
          </>
        }
      >
        <p>Are you sure you want to remove this payment method? This action cannot be undone.</p>
      </Modal>

      {/* Set Default Confirmation Modal */}
      <Modal
        isOpen={defaultConfirmOpen}
        onClose={() => setDefaultConfirmOpen(false)}
        title="Set as Default Payment Method"
        actions={
          <>
            <button 
              className="modal-button secondary" 
              onClick={() => setDefaultConfirmOpen(false)}
            >
              Cancel
            </button>
            <button 
              className="modal-button primary" 
              onClick={confirmSetDefault}
            >
              Confirm
            </button>
          </>
        }
      >
        <p>This will be used as your default payment method for future charges. Continue?</p>
      </Modal>

      {showAddCard && (
        <Elements stripe={stripePromise}>
          <AddCard 
            onClose={() => setShowAddCard(false)}
            onSuccess={() => {
              fetchPaymentMethods();
              setSuccessMessage('Payment method added successfully');
              setTimeout(() => setSuccessMessage(''), 3000);
            }}
          />
        </Elements>
      )}
    </div>
  );
};

export default PaymentMethods;