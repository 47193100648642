import React, { useCallback, useContext, useEffect, useState } from 'react';
import GridLayout from 'react-grid-layout';
import { Pencil, Save } from 'lucide-react';
import '../assets/styles/Dashboard.css';
import 'react-grid-layout/css/styles.css';
import LoggedInHeader from '../components/LoggedInHeader';
import Graph from '../components/Graph';
import AuthLogin from '../profile/auth-login';
import TermsAndConditionsPopup from '../components/TermsAndConditionsPopup';

const Dashboard = () => {
  const ctx = useContext(AuthLogin);
  const [charts, setCharts] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [layout, setLayout] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth - 48);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth - 48);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const FINNTECH_API_URL = process.env.REACT_APP_API_URL;

  // Generate default layout based on charts
  const generateDefaultLayout = (charts) => {
    return charts.map((chart, i) => ({
      i: chart.id.toString(),
      x: (i % 3) * 8,  // More space between items
      y: Math.floor(i / 3) * 2,
      w: 6,            // Wider default width
      h: 2,            // Keep same height
      minW: 4,         // Minimum width
      maxW: 12         // Allow to be much wider
    }));
  };

  useEffect(() => {
    const fetchCharts = async () => {
      try {
        const url = `${FINNTECH_API_URL}/getUserDashboard/${ctx.userId}`;
        const response = await fetch(url);
        const data = await response.json();

        if (data.charts && data.layouts) {
          setCharts(data.charts);
          setLayout(data.layouts);
        } else {
          // Handle case where user has no dashboard yet
          setCharts([]);
          setLayout([]);
        }
      } catch (error) {
        console.error('Error fetching dashboard:', error);
        setCharts([]);
        setLayout([]);
      }
    };
    fetchCharts();
  }, [ctx.userId]);

  const handleRemoveFromDashboard = async (chartId) => {
    try {
      setCharts(prevCharts => prevCharts.filter(chart => chart.id !== chartId));
      setLayout(prevLayout => prevLayout.filter(item => item.i !== chartId.toString()));
      
      const requestBody = {
        "chartIdToRemove": chartId
      };
      
      if (!chartId) {
        throw new Error('chartId is required');
      }
      
      const url = `${process.env.REACT_APP_API_URL}/deleteFromDashboard/${ctx.userId}`;
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify(requestBody)
      });
      
      const responseText = await response.text();
      
      if (!response.ok) {
        throw new Error(`Failed to update dashboard: ${responseText}`);
      }
    } catch (error) {
      console.error('Error updating dashboard:', error);
      alert(`Failed to remove chart: ${error.message}`);
      
      try {
        const fetchResponse = await fetch(`${process.env.REACT_APP_API_URL}/getUserDashboard/${ctx.userId}`);
        const data = await fetchResponse.json();
        setCharts(data);
        setLayout(generateDefaultLayout(data));
      } catch (refreshError) {
        console.error('Error refreshing dashboard:', refreshError);
      }
    }
  };

  const handleSaveLayout = async () => {
    try {
      console.log('Current layout state:', layout); // Debug log
  
      const layoutData = {
        layouts: layout
      };
      
      console.log('Sending layout data:', layoutData); // Debug log
      
      const url = `${FINNTECH_API_URL}/saveLayout/${ctx.userId}`;
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify(layoutData)
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to save layout');
      }
  
      const result = await response.json();
      console.log('Save layout response:', result); // Debug log
  
      setIsEditMode(false);
    } catch (error) {
      console.error('Error saving layout:', error);
      alert('Failed to save layout configuration');
    }
  };

  const logUserAcceptedTermsAndConditions = useCallback(async (user_id) => {
    const url = `${FINNTECH_API_URL}/logUserSignedTermsAndConditions/${user_id}`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      }
    });

    if (!response.ok) {
      throw new Error();
    }

    return await response.json();
  }, []);

  const handleTermsAndConditionsAccept = async (event) => {
    const log = await logUserAcceptedTermsAndConditions(ctx.userId);
    const userData = JSON.parse(localStorage.getItem('userInfo') || '[]');
    
    const updatedUserData = {
      ...userData,
      firstTimeLogin: "false",
      userAcceptedTermsAndConditions: "true"
    };
    
    localStorage.setItem('userInfo', JSON.stringify(updatedUserData));
    ctx.setFirstTimeLogin(false);
    ctx.setuserAcceptedTermsAndConditions(true);
  };

  const onLayoutChange = (newLayout) => {
    setLayout(newLayout);
  };

  return (
    <>
      {(!ctx.userAcceptedTermsAndConditions) ? (
        <TermsAndConditionsPopup onAccept={handleTermsAndConditionsAccept} />
      ) : (
        <>
          <LoggedInHeader />
          <div className="dashboard">
            <div className="dashboard-controls">
              {isEditMode ? (
                <button 
                  className="control-button save-button"
                  onClick={handleSaveLayout}
                >
                  <Save className="control-icon" />
                  Save Layout
                </button>
              ) : (
                <button 
                  className="control-button edit-button"
                  onClick={() => setIsEditMode(true)}
                >
                  <Pencil className="control-icon" />
                  Edit Layout
                </button>
              )}
            </div>
            <div className="dashboard-grid-container">
              <GridLayout
              className="dashboard-grid"
              layout={layout}
              cols={24}
              rowHeight={150}
              width={windowWidth}
              isDraggable={isEditMode}
              isResizable={isEditMode}
              onLayoutChange={onLayoutChange}
              margin={[8, 8]}  // Reduced from [16, 16]
              containerPadding={[8, 8]}  // Reduced from [16, 16]
              compactType={null}
              >
              {charts.map((chart) => (
                <div key={chart.id.toString()} className="chart-wrapper">
                  <Graph
                    {...chart}
                    showPlus={false}
                    isEditMode={isEditMode}  // Pass edit mode state
                    onRemove={() => handleRemoveFromDashboard(chart.id)}
                  />
                </div>
              ))}
              </GridLayout>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Dashboard;