import React from 'react';
import '../assets/styles/SubscriptionPricing.css';

const SubscriptionPricing = ({ selectedType, onTypeChange }) => {
  const TAX_RATE = 0.08; // 8% tax rate
  
  const plans = {
    monthly: {
      price: 2.99,
      interval: 'monthly',
      billing: 'Billed Monthly'
    },
    annual: {
      price: 29.99,
      interval: 'annual',
      billing: 'Billed Annually',
      savings: '2 Months Free!'
    }
  };

  const selectedPlan = plans[selectedType];
  const subtotal = selectedPlan.price;
  const tax = subtotal * TAX_RATE;
  const total = subtotal + tax;

  return (
    <div className="pricing-section">
      <h3 className="pricing-title">Subscription Plan</h3>
      
      <div className="plans-container">
        <div className="plans-grid">
          {/* Monthly Plan */}
          <div 
            className={`plan-card ${selectedType === 'monthly' ? 'selected' : ''}`}
            onClick={() => onTypeChange('monthly')}
          >
            <div className="plan-header">
              <h4>Monthly</h4>
              <input
                type="radio"
                checked={selectedType === 'monthly'}
                onChange={() => onTypeChange('monthly')}
                className="plan-radio"
              />
            </div>
            <div className="plan-price">${plans.monthly.price}/mo</div>
            <div className="plan-billing">{plans.monthly.billing}</div>
          </div>

          {/* Annual Plan */}
          <div 
            className={`plan-card ${selectedType === 'annual' ? 'selected' : ''}`}
            onClick={() => onTypeChange('annual')}
          >
            <div className="plan-header">
              <h4>Annual</h4>
              <input
                type="radio"
                checked={selectedType === 'annual'}
                onChange={() => onTypeChange('annual')}
                className="plan-radio"
              />
            </div>
            <div className="plan-price">${plans.annual.price}/year</div>
            <div className="plan-billing">{plans.annual.billing}</div>
            <div className="plan-savings">{plans.annual.savings}</div>
          </div>
        </div>

        {/* Price Breakdown */}
        <div className="price-breakdown">
          <div className="breakdown-row">
            <span>Subtotal</span>
            <span>${subtotal.toFixed(2)}</span>
          </div>
          <div className="breakdown-row">
            <span>Estimated Tax (8%)</span>
            <span>${tax.toFixed(2)}</span>
          </div>
          <div className="breakdown-row total">
            <span>Total</span>
            <span>${total.toFixed(2)}</span>
          </div>
          <div className="tax-note">
            Tax rates may vary based on your location
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionPricing;