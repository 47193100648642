import React, { useState } from 'react';
import { useNavigate, Link } from "react-router-dom";
import NavBar from '../components/NavBar';
import Logo from '../assets/images/logo.png';
import { Elements, useElements, useStripe, CardElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import StripeCardInput from '../components/StripeCardInput';
import SubscriptionPricing from '../components/SubscriptionPricing';
import '../assets/styles/Signup.css';

const stripePromise = loadStripe('pk_test_51QikgM2LqoSDKNiWnWTbdNFZskz0n89Xsa9sf4fHwrwXPH3sUuzo6w9WulNZ469wP6M8O76iwyvDKDpJPNnWiPlY00tWwdxtuY');

// Step 1: Account Creation Form
const AccountCreationForm = ({ formData, setFormData, onNext }) => {
    const [errors, setErrors] = useState({});
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordFeedback, setPasswordFeedback] = useState({
      length: false,
      uppercase: false,
      special: false
    });
  
    const validatePassword = (password) => {
      return {
        length: password.length >= 8,
        uppercase: /[A-Z]/.test(password),
        special: /[!@#$%^&*(),.?":{}|<>]/.test(password)
      };
    };
  
    const validateForm = () => {
      const newErrors = {};
      
      if (!formData.username || formData.username.length < 6) {
        newErrors.username = 'Username must be at least 6 characters';
      }
  
      // Enhanced password validation
      const passwordChecks = validatePassword(formData.password);
      if (!passwordChecks.length || !passwordChecks.uppercase || !passwordChecks.special) {
        newErrors.password = 'Password must contain 8 characters, one upercase, and a special character (@#$%&)';
      }

    // Password confirmation validation
      if (formData.password !== confirmPassword) {
        newErrors.confirmPassword = 'Passwords do not match';
      }
  
      if (!formData.firstName) {
        newErrors.firstName = 'First name is required';
      }
      if (!formData.lastName) {
        newErrors.lastName = 'Last name is required';
      }
      if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) {
        newErrors.email = 'Valid email is required';
      }
  
      setErrors(newErrors);
      return Object.keys(newErrors).length === 0;
    };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      onNext();
    }
  };

  return (
    <form onSubmit={handleSubmit} className="signup-form">
      <div className="form-group">
        <input
          type="text"
          placeholder="Username"
          value={formData.username}
          onChange={(e) => setFormData({ ...formData, username: e.target.value })}
          className="form-input"
        />
        {errors.username && <span className="error">{errors.username}</span>}
      </div>

      <div className="form-group">
        <input
          type="password"
          placeholder="Password"
          value={formData.password}
          onChange={(e) => setFormData({ ...formData, password: e.target.value })}
          className="form-input"
        />
        {errors.password && <span className="error">{errors.password}</span>}
      </div>

      <div className="form-group">
          <input
            type="password"
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            className="form-input"
          />
          {errors.confirmPassword && <span className="error">{errors.confirmPassword}</span>}
        </div>

      <div className="form-group">
        <input
          type="text"
          placeholder="First Name"
          value={formData.firstName}
          onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
          className="form-input"
        />
        {errors.firstName && <span className="error">{errors.firstName}</span>}
      </div>

      <div className="form-group">
        <input
          type="text"
          placeholder="Last Name"
          value={formData.lastName}
          onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
          className="form-input"
        />
        {errors.lastName && <span className="error">{errors.lastName}</span>}
      </div>

      <div className="form-group">
        <input
          type="email"
          placeholder="Email"
          value={formData.email}
          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
          className="form-input"
        />
        {errors.email && <span className="error">{errors.email}</span>}
      </div>

      <button type="submit" className="next-btn">Next</button>
    </form>
  );
};

// Step 2: BillingForm with lazy-loaded Stripe
// In Signup.js

const BillingForm = ({ formData, setFormData, onBack }) => {
const navigate = useNavigate();
const stripe = useStripe();
const elements = useElements();

// Existing state
const [errors, setErrors] = useState({});
const [loading, setLoading] = useState(false);
const [promoCode, setPromoCode] = useState('');
const [stripeError, setStripeError] = useState(null);
const [agreedToTerms, setAgreedToTerms] = useState(false);
const [termsError, setTermsError] = useState('');
const [subscriptionType, setSubscriptionType] = useState('monthly');

// Existing states list
const states = [
    'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA',
    'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD',
    'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ',
    'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC',
    'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'
];

// New function for price amount
const getPriceAmount = () => {
    return subscriptionType === 'monthly' ? 299 : 2999;
};

// Updated validateForm to include payment validation
const validateForm = () => {
    const newErrors = {};
    
    if (!formData.street) newErrors.street = 'Street address is required';
    if (!formData.city) newErrors.city = 'City is required';
    if (!formData.state) newErrors.state = 'State is required';
    if (!formData.zipCode) newErrors.zipCode = 'ZIP code is required';
    if (!formData.zipCode.match(/^\d{5}$/)) newErrors.zipCode = 'Invalid ZIP code format';
    if (!agreedToTerms) {
    setTermsError('You must accept the terms and conditions to continue');
    return false;
    }

    setErrors(newErrors);
    setTermsError('');
    return Object.keys(newErrors).length === 0;
};

// Updated handleSubmit with Stripe integration
const handleSubmit = async (e) => {
    e.preventDefault();
    setStripeError(null);

    if (!validateForm() || !stripe || !elements) {
    return;
    }

    setLoading(true);

        // Before loadStripe
    // console.log("About to initialize Stripe with key:", stripeKey);

    try {
    const { error: cardError, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
        billing_details: {
        name: `${formData.firstName} ${formData.lastName}`,
        email: formData.email,
        address: {
            line1: formData.street,
            line2: formData.unit || undefined,
            city: formData.city,
            state: formData.state,
            postal_code: formData.zipCode,
            country: 'US'
        }
        }
    });

    if (cardError) {
        throw new Error(cardError.message);
    }

    const response = await fetch(`${process.env.REACT_APP_API_URL}/signup`, {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
        },
        body: JSON.stringify({
        username: formData.username,
        password: formData.password,
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        street: formData.street,
        unit: formData.unit,
        city: formData.city,
        state: formData.state,
        zipCode: formData.zipCode,
        paymentMethodId: paymentMethod.id,  // Changed from stripeToken
        subscriptionType,
        amount: getPriceAmount()
        })
    });

    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.error || 'Signup failed');
    }

    if (data.clientSecret) {
        const { error: confirmError } = await stripe.confirmCardPayment(data.clientSecret);
        if (confirmError) {
        throw new Error(confirmError.message);
        }
    }

    navigate('/login', { 
        state: { 
        message: 'Account created successfully! Please log in.' 
        }
    });

    } catch (error) {
    console.error('Payment Error:', error);
    setStripeError(error.message);
    if (elements) {
        elements.getElement(CardElement).clear();
    }
    } finally {
    setLoading(false);
    }
};

return (
    <form onSubmit={handleSubmit} className="signup-form">
    <div className="form-section">
        <SubscriptionPricing 
        selectedType={subscriptionType}
        onTypeChange={setSubscriptionType}
        />

        <h3>Billing Address</h3>
        <div className="form-group">
        <input
            type="text"
            placeholder="Street Address"
            value={formData.street}
            onChange={(e) => setFormData({ ...formData, street: e.target.value })}
            className={`form-input ${errors.street ? 'error-input' : ''}`}
            disabled={loading}
        />
        {errors.street && <span className="error">{errors.street}</span>}
        </div>

        <div className="form-group">
        <input
            type="text"
            placeholder="Unit/Apartment (optional)"
            value={formData.unit}
            onChange={(e) => setFormData({ ...formData, unit: e.target.value })}
            className="form-input"
            disabled={loading}
        />
        </div>

        <div className="form-row">
        <div className="form-group half">
            <input
            type="text"
            placeholder="City"
            value={formData.city}
            onChange={(e) => setFormData({ ...formData, city: e.target.value })}
            className={`form-input ${errors.city ? 'error-input' : ''}`}
            disabled={loading}
            />
            {errors.city && <span className="error">{errors.city}</span>}
        </div>

        <div className="form-group half">
            <select
            value={formData.state}
            onChange={(e) => setFormData({ ...formData, state: e.target.value })}
            className={`form-input form-select ${errors.state ? 'error-input' : ''}`}
            disabled={loading}
            >
            <option value="">Select State</option>
            {states.map(state => (
                <option key={state} value={state}>{state}</option>
            ))}
            </select>
            {errors.state && <span className="error">{errors.state}</span>}
        </div>
        </div>

        <div className="form-row">
        <div className="form-group half">
            <input
            type="text"
            placeholder="ZIP Code"
            value={formData.zipCode}
            onChange={(e) => setFormData({ ...formData, zipCode: e.target.value })}
            className={`form-input ${errors.zipCode ? 'error-input' : ''}`}
            maxLength="5"
            disabled={loading}
            />
            {errors.zipCode && <span className="error">{errors.zipCode}</span>}
        </div>
        
        <div className="form-group half">
            <input
            type="text"
            value="United States"
            readOnly
            disabled
            className="form-input disabled-input"
            />
            <span className="info-text">Currently only accepting U.S. customers</span>
        </div>
        </div>
    </div>

    <div className="form-section">
        <h3>Payment Information</h3>
        <div className="card-element-container">
        <CardElement options={{
            style: {
            base: {
                fontSize: '16px',
                color: '#424770',
                '::placeholder': {
                color: '#aab7c4',
                },
            },
            invalid: {
                color: '#9e2146',
            },
            },
        }} />
        </div>

        <div className="form-group">
        <input
            type="text"
            placeholder="Promo Code (optional)"
            value={promoCode}
            onChange={(e) => setPromoCode(e.target.value)}
            className="form-input"
            disabled={loading}
        />
        </div>
    </div>

    <div className="form-section terms-section">
        <h3>Terms & Conditions</h3>
        <div className="terms-content">
        <div className="terms-checkbox-container">
            <input
            type="checkbox"
            id="terms"
            checked={agreedToTerms}
            onChange={(e) => {
                setAgreedToTerms(e.target.checked);
                if (e.target.checked) setTermsError('');
            }}
            className="terms-checkbox"
            disabled={loading}
            />
            <label htmlFor="terms" className="terms-label">
            I have read and agree to the <Link to="/terms-and-conditions" target="_blank" className="terms-link">Terms and Conditions</Link>
            </label>
        </div>
        {termsError && <span className="error">{termsError}</span>}
        </div>
    </div>

    {stripeError && (
        <div className="error-message">
        {stripeError}
        </div>
    )}

    <div className="button-group">
        <button 
        type="button" 
        onClick={onBack} 
        className="back-btn"
        disabled={loading}
        >
        Back
        </button>
        <button 
        type="submit" 
        className="submit-btn"
        disabled={loading}
        >
        {loading ? 'Processing...' : 'Complete Signup'}
        </button>
    </div>
    </form>
);
};

const Signup = () => {
    const navigate = useNavigate();
    const [currentStep, setCurrentStep] = useState(1);
    const [formData, setFormData] = useState({
      username: '',
      password: '',
      firstName: '',
      lastName: '',
      email: '',
      street: '',
      unit: '',
      city: '',
      state: '',
      zipCode: '',
      country: ''
    });
  
    // Stripe configuration options
    const stripeOptions = {
      appearance: {
        theme: 'stripe',
        variables: {
          colorPrimary: '#000000',
        },
      },
    };
  
    return (
      <>
        <NavBar />
        <div className="signup-container">
          <div className="progress-indicator">
            <div className={`step ${currentStep >= 1 ? 'active' : ''}`}>
              Account
            </div>
            <div className={`step ${currentStep >= 2 ? 'active' : ''}`}>
              Billing
            </div>
          </div>
  
          {currentStep === 1 && (
            <AccountCreationForm
              formData={formData}
              setFormData={setFormData}
              onNext={() => setCurrentStep(2)}
            />
          )}
  
          {currentStep === 2 && (
            <Elements stripe={stripePromise} options={stripeOptions}>
              <BillingForm
                formData={formData}
                setFormData={setFormData}
                onBack={() => setCurrentStep(1)}
              />
            </Elements>
          )}
        </div>
      </>
    );
  };
  
  export default Signup;