import React from "react";
import LoggedInHeader from "../components/LoggedInHeader";
import "../assets/styles/Books.css";

const Videos = () => {
    const videos = [
        {
          id: 1,
          title: "Peter Lynch Lecture On The Stock Market | 1997",
          author: "Peter Lynch",
          description: "Peter Lynch held a lecture (speech + Q&A) at an investment conference on the topic, investing in the stock market.",
          image: "peter-lynch-speech.PNG",
          link: "https://youtu.be/myu4Nv0pt0U?si=afJYdlBavT4_UKNl"
        },
        {
          id: 2,
          title: "How To Become A Millionaire: Index Fund Investing For Beginners",
          author: "Graham Stephen",
          description: "Index Fund Investing for beginners: This is one of the best investments that requires very little work, almost no skill, and has some of the best overall returns.",
          image: "graham-stephan.webp",
          link: "https://www.youtube.com/watch?v=fwe-PjrX23o&ab_channel=GrahamStephan"
        },
        {
          id: 3,
          title: "How to double your money",
          author: "Josh Brown",
          description: `Ritholtz Wealth Management CEO and Fast Money Halftime Report Trader Josh Brown on the power of compounding your money and the rule of 72.`,
          image: "josh-brown.webp",
          link: "https://youtu.be/mv4c86hIMGo?si=Z5bMbBp0q1TxwnQm&t=28"
        },
        {
          id: 4,
          title: "The 7 Baby Steps Explained",
          author: "Dave Ramsey",
          description: `1.  $1000 emergency fund
                        2.  Get out of debt (except mortgage)
                        3.  Save 3 - 6 months of expenses fully funded emergency fund
                        4.  Invest 15% (start Roth, then IRA, then 401K, )
                        5.  Save for children’s college funds
                        6.  Pay off mortgage (should take 5-8 years)
                        7.  Build wealth & Give Outrageously`,
          image: "dave-ramsey.webp",
          link: "https://www.youtube.com/watch?v=OO25TrVo_dU&ab_channel=TheRamseyShowHighlights"
        },
        {
            id: 5,
            title: "Daily Stock News, Updates, and Technical Analysis",
            author: "FX Evolution",
            description: `Own your future. Learn trading skills online.
                          FX Evolution was founded in 2010 by a group of fund managers and private traders looking to help people learn industry trading skills.`,
            image: "fx-evolution.jpg",
            link: "https://www.youtube.com/@fxevolutionvideo"
        }
      ];

  return (
    <>
    <LoggedInHeader />
    <div className="books-container">
      <div className="content">
        {videos.map((video) => (
            <a 
            key={video.id} 
            href={video.link} 
            target="_blank" 
            rel="noopener noreferrer" 
            className="book-card"
          >
            <div className="book-image">
              <img src={video.image} alt={video.title} />
            </div>
            <div className="book-details">
              <h2>{video.title}</h2>
              <h3>{video.author}</h3>
              <p>{video.description}</p>
            </div>
          </a>
        ))}
      </div>
    </div>
    </>
  );
};

export default Videos;