// Learn.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../assets/styles/Learn.css';
import LoggedInHeader from '../components/LoggedInHeader';
import bookIcon from '../assets/images/book.png';
import videoIcon from '../assets/images/video.png';
import podcastIcon from '../assets/images/podcast.png';
import playIcon from '../assets/images/play.png';
import strategyIcon from '../assets/images/strategy.png';
import advancedIcon from '../assets/images/advanced.png';

const Learn = () => {
  const navigate = useNavigate();

  return (
    <div className="learn-container">
      <LoggedInHeader />
      
      <div className="learn-content">
        <div className="learn-grid">

          {/* How to Start Section */}
          <div className="learn-card" 
          onClick={() => navigate('/how-to-start/')}>
            <img src={playIcon} alt="How to Start" className="card-icon" />
            <div className="card-content">
              <h2>How to Start</h2>
              <p>New to investing? This section is your starting point. Discover easy-to-follow guides, essential tips, and practical advice on how to begin your investment journey.</p>
            </div>
          </div>

          {/* Books Section */}
          <div className="learn-card"   
            onClick={() => navigate('/books/')}>
            <img src={bookIcon} alt="Books" className="card-icon" />
            <div className="card-content">
              <h2>Books</h2>
              <p>Explore our curated selection of essential reads that cover the fundamentals of investing, strategies, and the latest trends in the financial markets.</p>
            </div>
          </div>

          {/* Videos Section */}
          <div className="learn-card"
          onClick={() => navigate('/videos/')}>
            <img src={videoIcon} alt="Videos" className="card-icon" />
            <div className="card-content">
              <h2>Videos</h2>
              <p>Dive into our comprehensive video library featuring expert tutorials, market analyses, and step-by-step guides.</p>
            </div>
          </div>

          {/* Podcasts Section */}
          <div className="learn-card"
          onClick={() => navigate('/podcasts/')}>
            <img src={podcastIcon} alt="Podcasts" className="card-icon" />
            <div className="card-content">
              <h2>Podcasts</h2>
              <p>Tune into our recommended podcasts to stay informed and inspired by industry leaders and market experts.</p>
            </div>
          </div>

          {/* Strategies Section */}
          <div className="learn-card"
          onClick={() => navigate('/strategies/')}>
            <img src={strategyIcon} alt="Strategies" className="card-icon" />
            <div className="card-content">
              <h2>Strategies</h2>
              <p>Here, you'll find detailed explanations of various investment approaches that align with your financial goals and risk tolerance.</p>
            </div>
          </div>

          {/* Advanced Section */}
          <div className="learn-card"
          onClick={() => navigate('/Advanced/')}>
            <img src={advancedIcon} alt="Advanced" className="card-icon" />
            <div className="card-content">
              <h2>Advanced</h2>
              <p>Ready to take your investing skills to the next level? Our advanced section delves into complex topics and sophisticated techniques used by professional investors.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Learn;