import React from "react";
import LoggedInHeader from "../components/LoggedInHeader";
import "../assets/styles/Books.css";

const Advanced = () => {
    const lessons = [
        {
          id: 1,
          title: "Basics of Technical Analysis",
          description: "financial analysis that uses patterns in market data to identify trends and make predictions",
          image: "/advanced/TAIntro.avif",
          link: "https://youtu.be/Yv2iYYewdf0?si=LWTyHGf_lsGF-cez"
        },
        {
          id: 2,
          title: "Moving Averages",
          description: "A moving average is a technical indicator that investors and traders use to determine the trend direction of securities. It is calculated by adding up all the data points during a specific period and dividing the sum by the number of time periods. Moving averages help technical traders to generate trading signals.",
          image: "/advanced/simpleMovingAverages.avif",
          link: "https://youtu.be/IvvUbh-cnX4?si=7YzECks36dXPLFW4"
        },
        {
          id: 3,
          title: "Relative Strength Index",
          description: "The RSI is a momentum oscillator that is widely used in technical analysis of stocks and commodities to identify changes in momentum and price direction.",
          image: "/advanced/RSI.avif",
          link: "https://youtu.be/hbcCykbX14U?si=IyoqDCceqXzmi1gv"
        },
        {
            id: 4,
            title: "Moving Average Convergence Divergence",
            description: "Moving average convergence/divergence (MACD) is a technical indicator to help investors identify price trends, measure trend momentum, and identify entry points for buying or selling.",
            image: "/advanced/MACD.avif",
            link: "https://youtu.be/VoI9fzjNWDU?si=kY5ZUOshyKb9ylGK"
        },
        {
            id: 5,
            title: "Volume",
            description: `Volume is the number of shares of a stock traded on a given day.`,
            image: "/advanced/Volume.avif",
            link: "https://youtu.be/KOoW7toVIC0?si=LT1xKqTMg1Dql59s&t=11"
        }
      ];

  return (
    <>
    <LoggedInHeader />
    <div className="books-container">
      <div className="content">
        {lessons.map((lesson) => (
            <a 
            key={lesson.id} 
            href={lesson.link} 
            target="_blank" 
            rel="noopener noreferrer" 
            className="book-card"
          >
            <div className="book-image">
              <img src={lesson.image} alt={lesson.title} />
            </div>
            <div className="book-details">
              <h2>{lesson.title}</h2>
              <h3>{lesson.author}</h3>
              <p>{lesson.description}</p>
            </div>
          </a>
        ))}
      </div>
    </div>
    </>
  );
};

export default Advanced;