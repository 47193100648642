import React from "react";
import LoggedInHeader from "../components/LoggedInHeader";

import { AlertTriangle } from 'lucide-react';
import "../assets/styles/Strategies.css";

const Strategies = () => {
  const strategies = [
    {
      id: 1,
      title: "Conservative Strategy",
      pros: [
        "Less risky approach that helps protect your money from big market swings",
        "Your investment account value tends to stay more stable compared to stock-heavy approaches",
        "You receive regular payments from your bonds, providing steady income",
        "Keeps extra cash available so you can buy more stocks when prices drop (like shopping during a sale)"
      ],
      cons: [
        "When the stock market is doing really well, you won't make as much money as others might",
        "Since you have less money in stocks, you might miss out on bigger gains when companies do well",
        "When interest rates change (like when the Federal Reserve makes announcements), your bonds might temporarily lose some value",
        "If you're young and have many years until retirement, this careful approach might be too cautious"
      ],
      explanation: "This is a conservative investment approach that's perfect for people who get nervous about losing money in the stock market. Let me break down the key investments in simple terms: For the bonds portion: A high-yield savings account is like a regular savings account but with better interest rates. Bond ETFs (Exchange Traded Funds) are bundles of loans that trade like stocks. Two popular ones are: BND (Vanguard Total Bond Market ETF): Think of it as owning tiny pieces of thousands of different loans to the government and big companies, and AGG (iShares Core U.S. Aggregate Bond ETF): Very similar to BND, just managed by a different company. For the stocks portion: VOO (Vanguard S&P 500 ETF): This gives you ownership in 500 of America's largest companies like Apple, Microsoft, and Amazon. SPY (SPDR S&P 500 ETF): Does the same thing as VOO, just managed by a different company. This strategy is especially good for: People near retirement who can't risk losing their savings, anyone who loses sleep worrying about stock market crashes, investors who want their account value to stay relatively stable, and those who want to always have some money ready to invest when stock prices drop.",
      image: "conservative-strategy.PNG"
    },
    {
      id: 2,
      title: "Simple Strategy",
      pros: [
        "Better chance of growing your money over the long term than a conservative approach",
        "Your money is more likely to keep up with rising prices (inflation) over time",
        "History shows this mix of stocks and bonds has worked well for many people",
        "Still keeps some of your money in safer investments through bonds",
        "Good for people who can wait 10+ years before needing their money"
      ],
      cons: [
        "Your account value will go up and down more than if you had more bonds",
        "During stock market crashes, you might see bigger drops in your account",
        "You'll get less regular income than you would with more bonds",
        "You need to be comfortable seeing your account value change frequently"
      ],
      explanation: "This strategy puts 70% of your money in stocks and 30% in bonds, making it a good middle-ground approach. For stocks, you'll use either VOO (Vanguard S&P 500 ETF) or SPY (SPDR S&P 500 ETF), which both invest in 500 of America's biggest companies. For bonds, you'll use either BND (Vanguard Total Bond Market ETF) or AGG (iShares Core U.S. Aggregate Bond ETF), which both invest in thousands of government and company loans. This approach is great for people in their 30s or 40s saving for retirement - you're taking enough risk to grow your money, but still keeping some safety with bonds. Think of it like driving a car: you're going fast enough to get somewhere, but not so fast that you're being reckless.",
      image: "simple-strategy.PNG"
    },
    {
      id: 3,
      title: "Three Fund Strategy",
      pros: [
        "Spreads your money across the whole world, not just U.S. companies",
        "If one country's market is down, others might be up, helping protect your money",
        "Easy to understand but still sophisticated enough for experienced investors",
        "You own pieces of thousands of companies worldwide",
        "Keeps costs low since you're using simple index funds"
      ],
      cons: [
        "International investments can be affected by changing currency values",
        "Sometimes U.S. stocks do better, and you might wish you had more money there",
        "You need to occasionally adjust how much money is in each fund",
        "Bonds might not keep up when prices are rising quickly",
        "International stocks might change value more dramatically"
      ],
      explanation: "This strategy divides your money equally between three investments: U.S. bonds (using BND or AGG), U.S. stocks (using VTI - Vanguard Total Stock Market ETF, which owns practically every U.S. company), and international stocks (using VXUS - Vanguard Total International Stock ETF, which owns companies from around the world outside the U.S.). Think of it like not putting all your eggs in one basket - you're spreading your money across different types of investments and different countries. Each part plays a role: bonds are like your safety net, U.S. stocks are for growth from American companies, and international stocks let you benefit from growth in other countries. This is perfect for people who want a simple way to invest in the whole world.",
      image: "three-fund-portfolio.PNG"
    },
    {
      id: 4,
      title: "Finn Tech Strategy",
      pros: [
        "Chance for higher returns by investing in growing technology companies",
        "Money spread across both traditional companies and new digital investments",
        "Includes Warren Buffett's company, known for picking good investments",
        "Small amount in Bitcoin and Ethereum lets you participate in digital currency growth",
        "Mix of established big companies and newer tech firms"
      ],
      cons: [
        "Your account value might change a lot, especially in the tech portions",
        "Lots of money in technology companies, which might all go down together",
        "Big part of your money in one company (Berkshire Hathaway)",
        "The cryptocurrency portion could go up or down dramatically",
        "No bonds means less protection when markets go down"
      ],
      explanation: "This is an aggressive strategy for people comfortable with risk. It uses five main investments: VOO (S&P 500 ETF) for big U.S. companies, QQQ (Invesco QQQ ETF) which focuses on major technology companies like Apple and Google, BRK.B (Berkshire Hathaway stock) which is Warren Buffett's company known for long-term investing success, plus small amounts of Bitcoin and Ethereum (digital currencies). Think of this like a high-tech investment recipe: some ingredients are tried and true (like the S&P 500), some are focused on innovation (like QQQ), and some are very new (like cryptocurrencies). This strategy is best for people who: have at least 10 years before needing the money, don't panic when investments drop in value, and believe technology will continue to grow in importance. Warning: this is like a roller coaster - exciting but with big ups and downs!",
      image: "finn-tech-portfolio.PNG"
    },
    {
      id: 5,
      title: "\"VT and Chill\" Strategy",
      pros: [
        "Couldn't be simpler - just one investment to buy and hold",
        "Owns pieces of nearly every public company in the world",
        "Very low fees, keeping more money in your pocket",
        "No need to adjust or rebalance different investments",
        "History shows the world's stock markets tend to grow over long periods"
      ],
      cons: [
        "Account value will swing up and down a lot with no bonds to calm things down",
        "During market crashes, you might see very large drops in value",
        "Many people find it hard to stick with during scary market times",
        "No way to adjust how much risk you're taking",
        "Need to wait a very long time (15+ years) for best results"
      ],
      explanation: "This strategy uses just one investment: VT (Vanguard Total World Stock ETF), which buys tiny pieces of almost every publicly traded company on Earth. It's called 'VT and chill' because once you buy it, you just relax (or 'chill') and let it do its thing - no adjusting, no worrying about which countries are doing better or worse, just buy and hold for the very long term. It's like buying a tiny piece of every store, factory, and service company in the world, then sitting back and letting them work for you. This is perfect for people who: believe the world economy will keep growing over decades, want the simplest possible investment strategy, and have the patience to hold on through big market drops. Remember: this is like going on a very long journey - there will be rough patches, but historically, staying on the path has been rewarding for patient investors.",
      image: "vt-and-chill.PNG"
    }
  ];

  return (
    <>
      <LoggedInHeader />
      <div className="warning-banner">
        <p>Before investing, consider these key principles: Only invest money you won't need for at least 18 months. 
          Never risk more than you can afford to lose. 
          The best investors maintain enough uninvested cash to buy more stocks when the market goes down.</p>
      </div>
      <hr />
      <h1 className="strategy-header">Portfolio Allocations</h1>
      {strategies.map((strategy) => (
        <>

        <div key={strategy.id} className="comparison-header">
          <h2 className="comparison-title">{strategy.title}</h2>
          <div className="image-container">
            <img 
              src={strategy.image} 
              alt={strategy.title} 
              className="strategy-image"
            />
          </div>
          <div className="account-summary">
            <div className="account-type">
              <h3>Pros</h3>
              <ul>
                {strategy.pros.map((pro, index) => (
                  <li key={index}>{pro}</li>
                ))}
              </ul>
            </div>
            <div className="vs-divider"></div>
            <div className="account-type">
              <h3>Cons</h3>
              <ul>
                {strategy.cons.map((con, index) => (
                  <li key={index}>{con}</li>
                ))}
              </ul>
            </div>
          </div>
          <div className="explanation-section">
            <h3>Explanation</h3>
            <p>{strategy.explanation}</p>
          </div>
        </div>
        <hr />
        </>
      ))}
      <h1 className="strategy-header">Rebalancing Explaination</h1>
      <div className="rebalance-img">
        <img src="rebalance.PNG" />
      </div>
      <div className="rebalance-explanation">
      <p>
        Imagine you have a cookie jar that you want to keep half full of chocolate cookies and half full of sugar cookies. Over time, 
        you eat more sugar cookies, so now your jar has too many chocolate cookies. Rebalancing is like adding more sugar cookies 
        and removing some chocolate cookies to get back to your perfect 50/50 mix.
      </p>
      
      <div className="example-box">
        <h3>Simple Example:</h3>
        <p>Start with $10,000:</p>
        <ul>
          <li>$5,000 in stocks (50%)</li>
          <li>$5,000 in bonds (50%)</li>
        </ul>
        <p>After stocks grow and bonds stay flat:</p>
        <ul>
          <li>$7,000 in stocks (58%)</li>
          <li>$5,000 in bonds (42%)</li>
        </ul>
        <p>To rebalance: Sell $1,000 of stocks and buy $1,000 of bonds to get back to 50/50</p>
      </div>

      <div className="tip-box">
        <h3>When to Rebalance:</h3>
        <ul>
          <li>Pick regular times (like every 6 months)</li>
          <li>When your mix is off by more than 5-10%</li>
          <li>Many people do it once or twice a year</li>
        </ul>
      </div>
    </div>

    </>
  );
};

export default Strategies;