import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import '../assets/styles/Graph.css';
import { Card } from './Card';
import { HelpCircle, Maximize2, X, Plus, Trash } from 'lucide-react';
import ReactTooltip from 'react-tooltip';



const Graph = (props) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const plus = (
    <button className="control-button"
    onClick={(e) => {
      e.stopPropagation();
      props.onAdd();
    }}>
      <Plus className="control-icon" size={20} />
    </button>
  );

  const trash = (
    <button 
      className="control-button"
      onClick={(e) => {
        e.stopPropagation();
        props.onRemove();
      }}
    >
      <Trash className="control-icon" size={20} />
    </button>
  );

  return (
    <>
      <Card key={props.id} className="chart-card">
        {/* Regular chart view stays the same */}
        <div className="chart-header">
          <HelpCircle 
            data-tip={props.tooltip}
            className="help-icon"
          />
          <ReactTooltip place="top" effect="solid" />
          <h3 className="chart-title">{props.title}</h3>
          <div className="chart-controls">
            {props.showPlus ? plus : (props.isEditMode && trash)}
            <button 
              className="control-button"
              onClick={toggleExpand}
            >
              <Maximize2 className="control-icon" size={20} />
            </button>
          </div>
        </div>
        <iframe 
          src={props.url} 
          className='chart-placeholder'
        />
      </Card>

      {/* Use portal for fullscreen view */}
      {isExpanded && 
        ReactDOM.createPortal(
          <div className="expanded-overlay">
            <div className="expanded-content">
              <div className="expanded-header">
                <h3 className="chart-title">{props.title}</h3>
                <button 
                  className="dashboard-close-button"
                  onClick={toggleExpand}
                >
                  <X className="control-icon" size={24} color='black' />
                </button>
              </div>
              <iframe 
                src={props.url} 
                className='expanded-chart'
              />
            </div>
          </div>,
          document.body
        )}
    </>
  );
};

export default Graph;