import React from "react";
import LoggedInHeader from "../components/LoggedInHeader";
import InvestmentComparison from "../components/InvestmentComparison";
import RobinhoodPromo from "../components/RobinhoodPromo";


const HowToStart = () => {

    return (
        <>
        <LoggedInHeader />
        <RobinhoodPromo />
        <InvestmentComparison /> 
        </>
    )
};

export default HowToStart;