import React from "react";
import LoggedInHeader from "../components/LoggedInHeader";
import "../assets/styles/Books.css";

const Podcasts = () => {
    const podcasts = [
        {
          id: 1,
          title: "The Compound",
          author: "Josh Brown and Michael Batnick",
          description: "The Compound brings you the latest in business, investing, economics, finance, and much more! Michael Batnick, Downtown Josh Brown, Barry Ritholtz, Ben Carlson, and the rest of the gang upload new videos weekly!",
          image: "compound.jpg",
          link: "https://www.youtube.com/@TheCompoundNews"
        },
        {
          id: 2,
          title: "Financial Audit",
          author: "Caleb Hammer",
          description: "Having the conversations that I wish someone had with me over a decade ago.",
          image: "calebhammer.jpg",
          link: "https://www.amazon.com/Richest-Man-Babylon-George-Clason/dp/1505339111/"
        }
      ];

  return (
    <>
    <LoggedInHeader />
    <div className="books-container">
      <div className="content">
        {podcasts.map((podcast) => (
            <a 
            key={podcast.id} 
            href={podcast.link} 
            target="_blank" 
            rel="noopener noreferrer" 
            className="book-card"
          >
            <div className="book-image">
              <img src={podcast.image} alt={podcast.title} />
            </div>
            <div className="book-details">
              <h2>{podcast.title}</h2>
              <h3>{podcast.author}</h3>
              <p>{podcast.description}</p>
            </div>
          </a>
        ))}
      </div>
    </div>
    </>
  );
};

export default Podcasts;