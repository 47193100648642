import React from 'react';
import '../assets/styles/InvestmentComparison.css';

const InvestmentComparison = () => {
  return (
    <div className="comparison-container">
      <div className="comparison-header">
        <h2 className="comparison-title">Investment Account Comparisons</h2>
        <div className="account-summary">
          <div className="account-type">
            <h3>Roth IRA</h3>
            <p>Best for retirement savings</p>
            <ul>
              <li>Tax-free growth and withdrawals in retirement</li>
              <li>Limited annual contributions ($7,000 in 2024)</li>
              <li>Longer investment horizon (20+ years)</li>
            </ul>
          </div>
          <div className="vs-divider">VS</div>
          <div className="account-type">
            <h3>Brokerage Account</h3>
            <p>Best for mid-term investing</p>
            <ul>
              <li>Flexible withdrawals without penalties</li>
              <li>No contribution limits</li>
              <li>Shorter investment horizon (18+ months)</li>
            </ul>
          </div>
          <div className="vs-divider">VS</div>
          <div className="account-type">
            <h3>High Yield Savings Account</h3>
            <p>Best for short-term investing</p>
            <ul>
              <li>Earn interest each month</li>
              <li>No contribution or withdrawal limits</li>
              <li>Shortest investment horizon (less than 18 months)</li>
            </ul>
          </div>
        </div>
      </div>
      
      <div className="table-wrapper">
        <table className="comparison-table">
          <thead>
            <tr>
              <th>Feature</th>
              <th>Roth IRA</th>
              <th>Brokerage Account</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="feature-cell">Contribution Limits (2024)</td>
              <td>$7,000 ($8,000 if age 50 or older)</td>
              <td>No limits</td>
            </tr>
            <tr>
              <td className="feature-cell">Income Restrictions</td>
              <td>Yes - income phase-out limits apply</td>
              <td>None</td>
            </tr>
            <tr>
              <td className="feature-cell">Tax on Contributions</td>
              <td>After-tax dollars (no tax deduction)</td>
              <td>After-tax dollars (no tax deduction)</td>
            </tr>
            <tr>
              <td className="feature-cell">Tax on Growth</td>
              <td>Tax-free growth</td>
              <td>Taxed annually on dividends and realized gains</td>
            </tr>
            <tr>
              <td className="feature-cell">Tax on Withdrawals</td>
              <td>Tax-free if qualified*</td>
              <td>Capital gains tax on profits when selling</td>
            </tr>
            <tr>
              <td className="feature-cell">Early Withdrawal</td>
              <td>10% penalty on earnings if withdrawn before age 59½ (see exceptions below)</td>
              <td>No penalties, but less than 1 year held is taxed as short-term capital gains</td>
            </tr>
            <tr>
              <td className="feature-cell">Required Minimum Distributions</td>
              <td>None during original owner's lifetime</td>
              <td>None</td>
            </tr>
            <tr>
              <td className="feature-cell">Access to Funds</td>
              <td>Limited - penalties may apply for early withdrawal</td>
              <td>Unlimited access</td>
            </tr>
            <tr>
              <td className="feature-cell">Estate Planning Benefits</td>
              <td>Excellent - tax-free inheritance for beneficiaries</td>
              <td>Step-up in basis at death</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="notes-section">
        <div className="note note-warning">
          <h3>Important Caveats for New Brokerage Accounts (Less than 5 years):</h3>
          <ul>
            <li>Pattern Day Trading (PDT) rules apply if account is under $25,000 - limited to 3 day trades within 5 business days</li>
            <li>Some brokers may limit margin trading or options trading for new accounts</li>
            <li>Higher risk of tax wash sale violations due to inexperience</li>
            <li>May have limited access to certain investment products or IPOs</li>
            <li>Could face higher trading fees or maintenance requirements</li>
          </ul>
        </div>

        <div className="note note-info">
          <p className="note-title">* Roth IRA Qualified Withdrawal Requirements:</p>
          <p>Account must be open for 5+ years AND one of the following must be true:</p>
          <ul>
            <li>Age 59½ or older</li>
            <li>Disability</li>
            <li>First-time home purchase (up to $10,000)</li>
            <li>Death (distribution to beneficiaries)</li>
          </ul>
        </div>

        <div className="note note-default">
          <p className="note-title">** Early Withdrawal Note:</p>
          <p>Contributions (but not earnings) can be withdrawn at any time without penalty from a Roth IRA.</p>
        </div>
      </div>
    </div>
  );
};

export default InvestmentComparison;