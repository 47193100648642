import React from 'react';
import { Mail, Linkedin } from "lucide-react";
import NavBar from "../components/NavBar";
import '../assets/styles/Contact.css';
import Footer from '../components/Footer';

const Contact = () => {
    return (
        <div className="contact-container">
            <NavBar />
            
            <main className="contact-main">
                <div className="profile-section">
                    {/* Header Section */}
                    <div className="header-content">
                        <div className="text-header">
                            <h1 className="name-heading">Albert (Finn) Lepree</h1>
                            <h2 className="title-heading">Software Engineer & Founder of FinnTech LLC</h2>
                        </div>
                        
                        {/* Image */}
                        <div className="image-container">
                            <img 
                                src="/contact/headshot.jpg"
                                alt="Albert Finn Lepree"
                                className="profile-image"
                                onError={(e) => {
                                    e.target.src = "/api/placeholder/300/300";
                                    e.target.alt = "Profile image placeholder";
                                }}
                            />
                        </div>
                    </div>

                    {/* Contact Information */}
                    <div className="contact-card">
                        <h2 className="card-heading">Get in Touch</h2>
                        
                        <div className="contact-links">
                            <div className="contact-item">
                                <Mail className="contact-icon" />
                                <a href="mailto:Finn@TheFinntech.com" className="contact-link">
                                    Finn@TheFinntech.com
                                </a>
                            </div>
                            
                            <div className="contact-item">
                                <Linkedin className="contact-icon" />
                                <a href="https://www.linkedin.com/in/albert-lepree/" className="contact-link">
                                    LinkedIn Profile
                                </a>
                            </div>
                        </div>
                    </div>

                    {/* Bio */}
                    <div className="bio-container">
                        <div className="bio-content">
                            <p className="bio-text">
                                Software Engineer and entrepreneur with a degree in Software Engineering and Mathematics minor from Florida Gulf Coast University (2022). I am also currently pursuing my Masters of Information Systems and Analytics. I have experience across multiple domains including automatic data ingestion, analytics, artificial intelligence, front-end development, and requirements engineering.
                            </p>
                            <p className="bio-text">
                                As the founder of Finn Tech LLC, I'm passionate about democratizing financial analytics through advanced machine learning solutions. Our mission is to empower retail investors with sophisticated predictive analytics tools traditionally reserved for institutional investors.
                            </p>
                        </div>
                    </div>
                </div>
            </main>
            <Footer/>
        </div>
    );
};

export default Contact;