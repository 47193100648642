import React from 'react';
import '../assets/styles/Pricing.css';
import { useNavigate } from 'react-router-dom';

const PricingCard = ({ plan, price, interval }) => {
  const navigate = useNavigate();

  return (
    <div className="pricing-card">
      <h2 className="plan-title">{plan}</h2>
      <div className="price">${price}</div>
      <div className="interval">/{interval}</div>
      <ul className="features">
        <li>• ALL FEATURES INCLUDED</li>
      </ul>
      <button className="purchase-button" onClick={() => navigate('/signup')}>
        Purchase
      </button>
    </div>
  );
};

const PricingComponent = () => (
  <div className="pricing-container">
    <div className="pricing-cards">
      <PricingCard plan="Monthly" price="2.99" interval="month" />
      <PricingCard plan="Yearly" price="29.99" interval="year" />
    </div>
  </div>
);

export default PricingComponent;