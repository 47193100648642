import React, { useEffect, useState } from "react";

const AuthLogin = React.createContext({
  isLoggedIn: false,
  user: null,
  fullName: null,
  encryptedPassword: null,
  role: null,
  isSubscribed: false,
  userId: null,
  firstTimeLogin: true,
  userAcceptedTermsAndConditions: false,
  onLogout: () => {},
  onLogin: (username, fullName, role, userId, firstTimeLogin, userAcceptedTermsAndConditions, encryptedPassword) => {},
  setuserAcceptedTermsAndConditions: () => {},
  setFirstTimeLogin: () => {}
});

export const AuthLoginProvider = (props) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const [fullName, setFullName] = useState(null);
  const [encryptedPassword, setEncryptedPassword] = useState(null);
  const [role, setRole] = useState(null);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [userId, setUserId] = useState(null);
  const [firstTimeLogin, setFirstTimeLogin] = useState(true);
  const [userAcceptedTermsAndConditions, setuserAcceptedTermsAndConditions] = useState(false);

  useEffect(() => {
    const storedUserLoggedInInformation = localStorage.getItem("isLoggedIn");
    const storedUserInfo = JSON.parse(localStorage.getItem("userInfo"));

    if (storedUserLoggedInInformation === "1" && storedUserInfo) {
      setIsLoggedIn(true);
      setUser(storedUserInfo.username);
      setFullName(storedUserInfo.fullName);
      setEncryptedPassword(storedUserInfo.encryptedPassword);
      setRole(storedUserInfo.role);
      setUserId(storedUserInfo.userId);
      setFirstTimeLogin(storedUserInfo.firstTimeLogin);
      setuserAcceptedTermsAndConditions(storedUserInfo.userAcceptedTermsAndConditions);
    }
  }, []);

  const logoutHandler = () => {
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("userInfo");
    setIsLoggedIn(false);
    setUser(null);
    setFullName(null);
    setEncryptedPassword(null);
    setRole(null);
    setIsSubscribed(false);
    setUserId(null);
    setFirstTimeLogin(true);
    setuserAcceptedTermsAndConditions(false);
  };

  const loginHandler = (username, fullName, role, userId, firstTimeLogin, userAcceptedTermsAndConditions, encryptedPassword) => {
    const userInfo = {
      username,
      fullName,
      role,
      userId,
      firstTimeLogin,
      userAcceptedTermsAndConditions,
      encryptedPassword
    };

    localStorage.setItem("isLoggedIn", "1");
    localStorage.setItem("userInfo", JSON.stringify(userInfo));
    
    setIsLoggedIn(true);
    setUser(username);
    setFullName(fullName);
    setEncryptedPassword(encryptedPassword);
    setRole(role);
    setUserId(userId);
    setFirstTimeLogin(firstTimeLogin);
    setuserAcceptedTermsAndConditions(userAcceptedTermsAndConditions);
  };

  return (
    <AuthLogin.Provider
      value={{
        isLoggedIn,
        user,
        fullName,
        encryptedPassword,
        role,
        isSubscribed,
        userId,
        firstTimeLogin,
        userAcceptedTermsAndConditions,
        setuserAcceptedTermsAndConditions,
        setFirstTimeLogin,
        onLogout: logoutHandler,
        onLogin: loginHandler
      }}
    >
      {props.children}
    </AuthLogin.Provider>
  );
};

export default AuthLogin;