import React, { useState, useContext } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import AuthLogin from '../profile/auth-login';
import '../assets/styles/AddCard.css';

const AddCard = ({ onClose, onSuccess }) => {
  const stripe = useStripe();
  const elements = useElements();
  const ctx = useContext(AuthLogin);
  
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isSetDefault, setIsSetDefault] = useState(false);
  const [billingDetails, setBillingDetails] = useState({
    firstName: '',
    lastName: '',
    street: '',
    unit: '',
    city: '',
    state: '',
    zipCode: ''
  });

  const states = [
    'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA',
    'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD',
    'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ',
    'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC',
    'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'
  ];

  const handleSubmit = async (e) => {
  e.preventDefault();
  setError(null);
  setIsLoading(true);

  if (!stripe || !elements) {
    setError('Payment processing not initialized.');
    setIsLoading(false);
    return;
  }

  try {
    // 1. Create payment method with Stripe Elements
    const { error: stripeError, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      billing_details: {
        name: `${billingDetails.firstName} ${billingDetails.lastName}`,
        address: {
          line1: billingDetails.street,
          line2: billingDetails.unit || undefined,
          city: billingDetails.city,
          state: billingDetails.state,
          postal_code: billingDetails.zipCode,
          country: 'US'
        }
      }
    });

    if (stripeError) {
      throw new Error(stripeError.message);
    }

    // 2. Send payment method ID to backend
    const response = await fetch(`${process.env.REACT_APP_API_URL}/add-payment-method`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userId: ctx.userId,
        paymentMethodId: paymentMethod.id,
        isDefault: isSetDefault,
        billingDetails
      })
    });

    if (!response.ok) {
      const data = await response.json();
      throw new Error(data.error || 'Failed to save payment method');
    }

    onSuccess();
    onClose();
  } catch (err) {
    console.error('Error adding payment method:', err);
    setError(err.message);
    if (elements) {
      elements.getElement(CardElement).clear();
    }
  } finally {
    setIsLoading(false);
  }
};

  return (
    <div className="add-card-overlay">
      <div className="add-card-modal">
        <div className="modal-header">
          <h2>Add New Payment Method</h2>
          <button className="close-button" onClick={onClose}>&times;</button>
        </div>

        <form onSubmit={handleSubmit} className="add-card-form">
          <div className="card-element-container">
            <label>Card Details</label>
            <CardElement options={{
              style: {
                base: {
                  fontSize: '16px',
                  color: '#32325d',
                  fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                  '::placeholder': {
                    color: '#aab7c4'
                  }
                },
                invalid: {
                  color: '#fa755a',
                  iconColor: '#fa755a'
                }
              }
            }} />
          </div>

          <div className="form-row">
            <div className="form-group">
              <label>First Name</label>
              <input
                type="text"
                value={billingDetails.firstName}
                onChange={(e) => setBillingDetails({
                  ...billingDetails,
                  firstName: e.target.value
                })}
              />
            </div>
            <div className="form-group">
              <label>Last Name</label>
              <input
                type="text"
                value={billingDetails.lastName}
                onChange={(e) => setBillingDetails({
                  ...billingDetails,
                  lastName: e.target.value
                })}
              />
            </div>
          </div>

          <div className="form-group">
            <label>Street Address</label>
            <input
              type="text"
              value={billingDetails.street}
              onChange={(e) => setBillingDetails({
                ...billingDetails,
                street: e.target.value
              })}
            />
          </div>

          <div className="form-group">
            <label>Unit/Apt (optional)</label>
            <input
              type="text"
              value={billingDetails.unit}
              onChange={(e) => setBillingDetails({
                ...billingDetails,
                unit: e.target.value
              })}
            />
          </div>

          <div className="form-row">
            <div className="form-group">
              <label>City</label>
              <input
                type="text"
                value={billingDetails.city}
                onChange={(e) => setBillingDetails({
                  ...billingDetails,
                  city: e.target.value
                })}
              />
            </div>
            <div className="form-group">
              <label>State</label>
              <select
                value={billingDetails.state}
                onChange={(e) => setBillingDetails({
                  ...billingDetails,
                  state: e.target.value
                })}
              >
                <option value="">Select State</option>
                {states.map(state => (
                  <option key={state} value={state}>{state}</option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label>ZIP Code</label>
              <input
                type="text"
                value={billingDetails.zipCode}
                maxLength="5"
                onChange={(e) => setBillingDetails({
                  ...billingDetails,
                  zipCode: e.target.value.replace(/\D/g, '')
                })}
              />
            </div>
          </div>

          <div className="form-group checkbox-group">
            <label>
              <input
                type="checkbox"
                checked={isSetDefault}
                onChange={(e) => setIsSetDefault(e.target.checked)}
              />
              Set as default payment method
            </label>
          </div>

          {error && <div className="error-message">{error}</div>}

          <div className="button-group">
            <button 
              type="button" 
              className="cancel-button" 
              onClick={onClose}
              disabled={isLoading}
            >
              Cancel
            </button>
            <button 
              type="submit" 
              className="submit-button"
              disabled={isLoading}
            >
              {isLoading ? 'Adding...' : 'Add Card'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddCard;