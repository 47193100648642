import React from 'react';
import { Link } from 'react-router-dom';

import '../assets/styles/Home.css';
import NavBar from "../components/NavBar";
import Logo from '../assets/images/logo.png';
import SnP500Ret from '../assets/images/AvgSnP500Ret.png';
import BlogList from "../components/BlogList"
import Pricing from "../components/Pricing"
import Footer from '../components/Footer';

const Home = () => {
    const scrollToSection = (sectionId) => {
        const element = document.getElementById(sectionId);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };
    
    return (
        <>
        <NavBar onSectionClick={scrollToSection} />
        
        <div className="homepage">
            <img src={Logo} alt='finntechLogo' className='homeLogo' />
            <h3 className="slogan">Investing Guidance For The Beginners and Advanced</h3>
            <div className='buttons'>
                <Link className='btn' to="/login">Login</Link>
                <Link className='btn' to="/signup">Sign Up</Link>
            </div>
            <img src={SnP500Ret} alt='homepageGraph' className='SnP500Img' />
        </div>
        <hr/>
        <div id="about">
            <h1 className='abouth1'>About</h1>
            <h4 className='aboutbody'>Welcome to Finn Tech, where we empower retail traders with institutional-level 
                analyitics. Our algorithimic trading platform delivers deep insights into stocks, 
                crypto, and economic trends, giving you the tools to make informed decisions. 
                From historical S&P 500 returns by month since 1945 to the latest market analysis, 
                we bring data to your fingertips. Join us to elevate your trading and investing 
                strategies with professional-grade intelligence</h4>
        </div>
        <hr/>
        <h1 className='abouth1'>Blog</h1>
        <BlogList />
        <hr/>
        <div id="pricing">
            <h1 className='abouth1'>Pricing</h1>
            <Pricing/>
        </div>
        <hr/>
        <div id="social-media">
            <Footer/>
        </div>
        </>
    );
};
    
export default Home;