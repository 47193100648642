import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import NavBar from '../components/NavBar';
import Header from '../components/Header';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [content, setContent] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/request-password-reset`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: email.toLowerCase() })
      });

      if (!response.ok) {
        setContent(
          <div className="errormessage">
            Unable to process request. Please try again.
          </div>
        );
        return;
      }

      setIsSubmitted(true);
    } catch (err) {
      setContent(
        <div className="errormessage">
          Server error. Please try again later.
        </div>
      );
    }
  };

  return (
    <>
      <NavBar />
      <Header />
      {content}
      <div className="dialogue">
        {!isSubmitted ? (
          <form onSubmit={handleSubmit}>
            <h1>RESET PASSWORD</h1>
            <p>Enter your email to receive reset instructions</p>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              required
              className="inputs"
            />
            <br />
            <button type="submit" className="submitbtn">
              Send Instructions
            </button>
            <br />
            <Link to="/login" className="forgot">
              Back to Login
            </Link>
          </form>
        ) : (
          <div className="success-dialogue">
            <h1>CHECK YOUR EMAIL</h1>
            <p>
              If an account exists for {email}, you will receive password reset
              instructions shortly.
            </p>
            <Link to="/login" className="submitbtn">
              Return to Login
            </Link>
          </div>
        )}
      </div>
    </>
  );
};

export default ForgotPassword;