// ResetPassword.js
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import NavBar from '../components/NavBar';
import Header from '../components/Header';
import '../assets/styles/ResetPassword.css';

const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [content, setContent] = useState(null);
  const [isValidToken, setIsValidToken] = useState(false);
  const navigate = useNavigate();
  const { token } = useParams();

  useEffect(() => {
    validateToken();
  }, [token]);

  const validateToken = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/validate-reset-token/${token}`);
      
      if (!response.ok) {
        setContent(
          <div className="errormessage">
            Invalid or expired reset link. Please request a new one.
          </div>
        );
        return;
      }

      setIsValidToken(true);
    } catch (err) {
      setContent(
        <div className="errormessage">
          Unable to validate reset link. Please try again.
        </div>
      );
    }
  };

  const validatePassword = (password) => {
    const minLength = password.length >= 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumbers = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    return minLength && hasUpperCase && hasLowerCase && hasNumbers && hasSpecialChar;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setContent(null);

    if (!validatePassword(password)) {
      setContent(
        <div className="errormessage">
          Password must be at least 8 characters and include uppercase, lowercase, 
          numbers, and special characters.
        </div>
      );
      return;
    }

    if (password !== confirmPassword) {
      setContent(
        <div className="errormessage">
          Passwords do not match
        </div>
      );
      return;
    }

    setIsLoading(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/reset-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({
          token,
          password
        })
      });

      if (!response.ok) {
        throw new Error(await response.text());
      }

      navigate('/login', { 
        state: { message: 'Password reset successful. Please log in with your new password.' }
      });

    } catch (err) {
      setContent(
        <div className="errormessage">
          {err.message || 'Failed to reset password. Please try again.'}
        </div>
      );
    } finally {
      setIsLoading(false);
    }
  };

  if (!isValidToken) {
    return (
      <>
        <NavBar />
        <Header />
        {content}
      </>
    );
  }

  return (
    <>
      <NavBar />
      <Header />
      {content}
      <div className="dialogue">
        <form onSubmit={handleSubmit}>
          <h1>RESET PASSWORD</h1>
          <p>Enter your new password</p>
          
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="New Password"
            required
            className="inputs"
            disabled={isLoading}
          />
          <br />
          
          <input
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            placeholder="Confirm New Password"
            required
            className="inputs"
            disabled={isLoading}
          />
          <br />
          
          <button 
            type="submit" 
            className="submitbtn"
            disabled={isLoading}
          >
            {isLoading ? 'Resetting...' : 'Reset Password'}
          </button>
        </form>
      </div>
    </>
  );
};

export default ResetPassword;