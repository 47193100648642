import React, {useContext, useEffect, useState} from 'react';
import '../assets/styles/AddCharts.css';
import LoggedInHeader from '../components/LoggedInHeader';
import Graph from '../components/Graph';
import AuthLogin from '../profile/auth-login';

const AddCharts = () => {
  const ctx = useContext(AuthLogin);
  const [charts, setCharts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredCharts, setFilteredCharts] = useState([]);

  useEffect(() => {
    const fetchCharts = async () => {
      try {
        const url = `${process.env.REACT_APP_API_URL}/getAllNonUserCharts/${ctx.userId}`
        const response = await fetch(url);
        const data = await response.json();
        setCharts(data);
        setFilteredCharts(data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchCharts();
  }, [ctx.userId]);

  useEffect(() => {
    const filtered = charts.filter(chart => 
      chart.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredCharts(filtered);
  }, [searchTerm, charts]);

  const handleAddToDashboard = async (chartId) => {
    try {
      setCharts(prevCharts => prevCharts.filter(chart => chart.id !== chartId));
      setFilteredCharts(prevCharts => prevCharts.filter(chart => chart.id !== chartId));
  
      const requestBody = {
        chartIdToAdd: chartId,
        defaultLayout: {
          i: chartId.toString(),
          x: 0, // These will be adjusted by the grid
          y: Infinity, // Put it at the bottom
          w: 6,
          h: 2
        }
      };
  
      if (!chartId) {
        throw new Error('chartId is required');
      }
  
      const url = `${process.env.REACT_APP_API_URL}/addToDashboard/${ctx.userId}`;
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify(requestBody)
      });
  
      if (!response.ok) {
        const responseText = await response.text();
        throw new Error(`Failed to update dashboard: ${responseText}`);
      }
  
    } catch (error) {
      console.error('Error updating dashboard:', error);
      alert(`Failed to add chart: ${error.message}`);
      
      try {
        const fetchResponse = await fetch(`${process.env.REACT_APP_API_URL}/getAllNonUserCharts/${ctx.userId}`);
        const data = await fetchResponse.json();
        setCharts(data);
        setFilteredCharts(data);
      } catch (refreshError) {
        console.error('Error refreshing charts:', refreshError);
      }
    }
  };

  return (
    <>
      <LoggedInHeader />
      <div className="add-charts-container">
        <div className="add-charts-search">
          <input
            type="text"
            placeholder="Search charts..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="add-charts-search-input"
          />
        </div>
        <div className="add-charts-grid">
          {filteredCharts.map((chart) => (
            <div key={chart.id} className="add-charts-item">
              <Graph 
                {...chart} 
                showPlus={true} 
                onAdd={() => handleAddToDashboard(chart.id)}
              />
            </div>
          ))}
        </div>
        {filteredCharts.length === 0 && (
          <div className="add-charts-empty-state">
            No charts found matching your search.
          </div>
        )}
      </div>
    </>
  );
};

export default AddCharts;