import React from 'react';
import '../assets/styles/RobinhoodPromo.css';

const RobinhoodPromo = () => {
    return (
        <div className="promo-container">
            <a 
                href="https://robinhood.com/signup" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="promo-link"
            >
                <div className="promo-content">
                    <img 
                        src="/robinhoodlogo.PNG" 
                        alt="Robinhood Logo" 
                        className="promo-logo"
                    />
                    <div className="promo-text">
                        Create a Robinhood account 
                        <br />
                        and open one of two types 
                        <br />
                        of accounts
                    </div>
                </div>
            </a>
        </div>
    );
};

export default RobinhoodPromo;